import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { toPlural, toSeoUrl } from 'helpers/HelperFunctions';
import styles from './Skill.module.scss';
import { skillRoleObject } from 'helpers/Constants';

const Skill = ({ skill, userType }) => {
  if (!skill) return <div className={styles.skillEmpty}></div>;
  let imgUrl = '';
  let divStyle;
  let skillClassName = 'backgroundImage';

  try {
    imgUrl =
      require(`static/images/portfolios/skills/${skill.slug.toLowerCase()}.png`).default;
    divStyle = { backgroundImage: `url(${imgUrl})` };
  } catch (err) {
    divStyle = {};
    skillClassName = 'noLogo';
  }

  const SkillTile = (
    <div className={styles.skillContainer}>
      <div className={styles.skill}>
        <div className={styles[skillClassName]} style={divStyle}>
          {!imgUrl && skill.name.slice(0, 2)}
        </div>
      </div>
      {skill.name}
    </div>
  );

  const role = toSeoUrl(toPlural(skillRoleObject[skill.name]));
  const linkToFindPage = role && userType === 'guest';
  let url = `/browse?page=1&skill=${skill.slug}`;
  if (linkToFindPage) url = `/find/${role}/with/${skill.slug}`;

  return userType === 'buyer' || linkToFindPage ? (
    <a href={url}>{SkillTile}</a>
  ) : (
    SkillTile
  );
};

Skill.propTypes = {
  skill: PropTypes.object,
  userType: PropTypes.string,
};

export default memo(Skill);
